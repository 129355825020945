import React, { Component } from 'react';

class Terms extends Component {
  render() {
    return (
      <div>
        <h1 className="title">Terms of Service</h1>
        <p>If you haven't been invited you will be removed as this is invite only.</p>
        <p>Currently the app is free and will remain that was for the forseable future.</p>
        <p>You are responsible for your own mobile data limits when using the app, every attempt has been made to keep data transfers to a minimum.</p>
        <p>Any abusive behaviour (through the chat/news feed capability) will result in you being removed from the app.</p>
        <p>Finally the administrator's decision on the scores calculated for each game is final and not disputes will be accepted.</p>
      </div>
    )
  }
}

export default Terms;