import React, { Component } from 'react';

class Privacy extends Component {
  render() {
    return (
      <div>
       <h1>Privacy Policy</h1>
<h2>Introduction</h2>
<p>This Privacy policy applies to each company listed in the Companies section at the base of this document ("Company", "us" or "we"), it explains how we collection, use and dislose information about you.</p>
<p>This Privacy policy applied to information which identifies you, or from which you can be reasonably be identified, ie: your email address</p>

<h2>How we use your email address?</h2>
<p>Your email address will only be used for account management purposes, ie: logging into the app</p>

<h2>How do we protect your information?</h2>
<p>We take reasonable steps to prevent unauthorised access to our online and computerised systems and by measures such as firewalls, data encryption, virus deteticion methods and password/mfa restricted access</p>

<h2> Companies </h2>
<p>Jadder Development House</p>
      </div>
    )
  }
}

export default Privacy;