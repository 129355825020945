import React, { Component } from 'react';

class Rules extends Component {
  render() {
    return (
      <div>
        <h1 className="title"> Superkick Rules</h1>
        <p>Welcome to Superkick for 2024 as the Boogie Woogie Sheep
        look to defend their premiership, I hope the rest of you are
        ready to try to take down the defending champ.</p>
       <p>However for the 1st time in Superkick history we are expanding to 2 competitions
        running concurrently. </p>
        <ol>
          <li>Superkick BBQ</li>
          <li>Superkick SMS</li>
        </ol>
        <p>We will have 2 premiers but only 1 BH Cup winner.</p>
        <h2>1. GAMEPLAY RULES</h2>
        <p>The idea of the game is to select 25 goalscorers from afl teams of
        which the top 8 will score for your superkick side in your head to
        head games each week.</p> 
        <h3>Example Game</h3>
        <p>Flying Saucers:</p>
          <p>Scores</p> 
          <ol>
            <li>Franklin 6.1</li>
            <li>Wingard 4.2 </li>
            <li>Giansiracusa 2.0</li>
            <li>Henderson 1.2</li>
            <li>McIntosh 1.1</li>
            <li>Lynch 1.1 </li>
            <li>Sandilands 1.1</li>
            <li>Daniher 1.0</li>
          </ol>
           <p>Non counted scores</p>
           <ol>
            <li>Black 1.0</li>
            <li>Hill 0.3 </li>
            <li>Edwards 0.1</li>
          </ol>
          <p>Score: 17.8.110</p>
          <p>Lost to</p>
          <p>East Quiche:</p> 
          <p>Scores</p>
          <ol>
            <li>Pavlich 5.3</li>
            <li>Day 4.0</li>
            <li>Crameri 3.3</li>
            <li>Betts 2.2</li>
            <li>Cooney 1.1</li>
            <li>Ballantyne 1.0</li>
            <li>Carlisle 1.0</li>
            <li>Varcoe 0.3</li>
          </ol>
          <p>Non Counted Scores</p>
          <ol>
            <li>Brown 0.1 </li>
          </ol>
          <p>Score: 17.12.114</p>

          <p><i>In this scenario East Quiche would win by 4 points even though overall
          the Saucers had more goals but the 9th goalscorer will not count.</i></p>
          <h2>2. THE DRAFT</h2>
          <p>The draft order is decided via goal scorers in a practice match.
          Each team will be randomly assigned players on random.org. The
          player kicking the 1 st goal will give that team pick 1. The next team
          that has their player kick a goal will get pick 2 and so on until the
          order is finalized. If 2 or more sides do not get a goal, the 1 st point
          kicked by their player will count. If still no score, I will use another
          game to decide the rest.
          </p>
          <p>The Superkick SMS draft will be decided in the Richmond v
          Melbourne game on Sunday February 18th.</p>
          <p>The Superkick BBQ draft order will be decided in the North
          Melbourne v Collingwood game on Wednesday 21 st February.</p>
          <p>The draft is run in a snake order.</p>
          <ol>
            <li>1st Round is pick 1 to pick 11</li>
            <li>2nd round order is reversed, the team who had pick 1 now has pick
          11.</li>
          </ol>
          <p>
            <i>After 10 years of trying to figure out how to make the draft quicker,
            things will change after the 8th round. In the 9th round and
            thereafter you will get 2 picks in a row for the remainder of the
            draft.</i>
          </p>


        <h2>3. PLAYER TRADING RULES/ADD ONS</h2>
        <p>a) Your side will eventually be made up of 25 but you can pass on
        draft day from pick 16 onwards. If you pass at pick 16 that will
        leave you 9 players to add on for the year. You could pass at pick
        19 leaving you 6 add ons or take all 25 players on draft day
        leaving you zero add ons. However you can trade from the
        unselected pool of players or add on players after draft day and
        prior to the opening round. The cut off time is the 1 st Saturday of
        each round. No trades or add ons for that round can be made
        after Gold Coast v Richmond on Saturday March 9 th 4.20pm. If an
        add on is made after this time it will count for the following
        round.</p> 
        <p>b) There will be no trading unless its prior to the opening round or
        one of your top 2 picks does a long term injury that consists of 8
        weeks plus. You will than trade out your top 2 pick for any
        previously unselected player. If your injured player comes back
        early they will not count until 8 weeks have been missed. To
        bring back your injured player you must trade out any other
        player in your team. Once round 17 is complete a season ending
        injury will not result in a trade as that will be less than 8 weeks.
        If your top 2 player misses 8 continuous weeks for any reason
        you can also have a bonus trade.</p> 


        <p>c) To make an add on in the Superkick BBQ comp you can make the
        trade yourself via the superkick app or ask myself or Charles to do it
        for you. Obviously doing it yourself is the easiest way to beat another
        Superkick side to the punch.
        For the Superkick SMS comp I will create a messenger group on
        facebook to announce any add ons. If you do not wish to go on
        messenger, message myself or Steven Giammario to post it in there
        for you.
        Injury/Retirements during the year is a case of bad luck, using top
        ups wisely is key.
        </p>

        <h2>4. Season Structure</h2>
        <p>There will be 20 home and away games for each SK side over the 1 st
        22 rounds, also 2 byes. When you have your 1 st bye you will compete
        in the <a>BH Cup</a> plus 3 rounds
        of finals. A random fixture will be made through random.com after
        each draft is complete.</p>
        <p>Each team will play one another twice. We will be playing through
        the bye rounds, so if your team is short on players for that week
        compared to your opponent it is just a case of bad luck. As an
        example the opening round has only 4 games. Be mindful on how
        you will attack that.</p>

        <h4>How ladder poistion is calculated</h4>
        <p>4 points will be awarded for a win and 2 points each for a draw, then percentage based on your own score divided by your opponents times 100.</p>

        <h4>Finals Structure</h4>
        <p>Teams 1-6 will play off in a top 6 finals format over 3 weeks to
        decide the winner.</p>

        <h4>Week 1</h4>
        <ol>
          <li>3v6</li>
          <li>4v5</li>
        </ol>

        <h4>Week 2</h4>
        <ol>
          <li>1st plays the lowest ranked winner from week 1</li>
          <li>2nd plays the highest ranked winner from week 1</li>
        </ol>


        <h4>Week 3 - GRAND FINAL WEEK</h4>
        <ol>
        <li>The 2 winners from week 2 will play off</li>
        <li>3rd place playoff</li>
        </ol>

        <p><i>Also note something different this year is the 3rd place playoff.
        Instead of awarding both losing preliminary finalists a prize, it
        makes more sense to only reward the 3rd place getter.</i></p>

        <h4> A DRAWN FINAL</h4>
        <p>In the event of a drawn final it will be come down to the 9 th best
        scorer. If still a tie the 10 th best etc until a winner is found.</p>
        <p>If a winner is still undetermined it will be the team that had the
        biggest goalscorer for the match and if still a draw the 2 nd biggest
        scorer until a winner is decided. If a winner still cannot be decided
        after those tiebreakers it will be whoever has finished higher on the
        ladder.</p>
        <p>Percentage shall decide the difference between the teams locked on
        points on the ladder and in the unlikely event of exact percentage it
        shall come down to head to head and the % between those sides
        during the year.</p>

        <p>If miraculously there was 2 draws between those sides and equal
        percentage for the whole year the final position on the ladder
        between those sides will be decided by a coin toss.</p>

        <h2>5. Brent Hodge Cup</h2>
        <p>The Brent Hodge Cup is back for 2024 for our late mate who inspired
        this competition back in 2000 and coached the Flying Saucers to the
        2001 flag before the comp took a 13 year hiatus and he re inspired it
        back in 2014.</p>
        <p>He passed away in 2015 and loved this game with a passion so we
        decided to dedicate a Cup in his name which was won by
        Bamboozled Asains coached by Andy Tcha in its inaugural year and
        are 2 time winners.</p>
        <p>Goalfest are the reigning champions who have gone back to back.</p>

        <h3>Format </h3>
        <p>This year’s format will be played in a knockout format through the
        year. When you have your 1 st bye, you will be matched up against the
        team that has the bye in the other competition. This is where SK BBQ
        meets SK SMS</p>
        <p>After the 1st rounds of byes (OR-Rd10) there will be 11 winners plus
        a lucky loser that advances. From all the losing teams, the lucky loser
        will be decided by who has the highest individual score for the year
        so far. For example the Goalfest lose their Rd 3 bye matchup but in
        round 8 they score 169 and no other losing team has scored higher
        than that so far in the season than they will be the lucky loser.
        In round 11, the 2 nd round of kockouts will occur in the following
        format.</p>
        <ol>
          <li>Rd 11 Opening round bye winner vs Rd 6 bye winner</li>
          <li>Rd 12 Rd 1 bye winner vs Rd 7 bye winner</li>
          <li>Rd 13 Rd 2 bye winner vs Rd 8 bye winner</li>
          <li>Rd 14 Rd 3 bye winner vs Rd 9 bye winner</li>
          <li>Rd 15 Rd 4 bye winner vs Rd 10 bye winner</li>
          <li>Rd 16 Rd 5 bye winner vs Lucky Loser</li>
        </ol>

        <h4>Round 3</h4>
        <p>This will happen between rounds 17-21. If one of the competing
        teams has a bye on one of the aforementioned rounds than that is
        when that game will be played.</p>
        <ol>
          <li>Rd 11 winner vs Round 14 winner</li>
          <li>Rd 12 winner vs Round 15 winner</li>
          <li>Rd 13 winner vs Round 16 winner</li>
        </ol>

        <p>From these 3 winners, the team that had the highest score in their
        round 3 matchup will advance straight to the BH Cup Final.
        The other 2 winners will play a semi final for the right to play against
        them.</p>
        <p>That semi final will be played as soon as possible after those 3
        matches are complete and again I will aim to play the game when
        either one or both sides have a bye. This will likely stretch into the
        regular season finals.</p>

        <p>If rounds are further shortened or an AFL game cancelled or
        moved due to any unforeseen circumstance the round will count
        as is. If a whole round is cancelled, the points will be shared.</p>

        <h2>PRIZES</h2>
        <h3>Cost of Entry is $120 entry per team</h3>
        <ol>
          <li>Winner $620</li>
          <li>Runner up $300</li>
          <li>3 rd Place playoff winner $180</li>
          <li>Highest season score $120</li>
          <li>BH Cup Winner $200</li>
        </ol>


        <p><b>Have fun at the draft</b></p>
        <p>Your League Administrator</p>
        <p>East Quiche Coach</p>
        <p>2014-15 premiers</p>

        <p><i>Please note some rules are subjected to change</i></p>
      </div>
    )
  }
}

export default Rules;